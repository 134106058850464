h3 {
    label {
        font-weight: 600 !important;
        font-size: 1.25rem !important;
        line-height: 1.25rem !important;
    }
}
.customer-addresses {
    max-height: 380px;
    overflow-y: scroll;
}