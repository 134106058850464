.main {
    width: 95%;
    margin: 30px auto;
}

.loader-wrapper {
    width: 100%;
    height: 25vw;
    position: relative;
    svg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
}