#customer-login-link {
    color: #000;
    font-weight: 600;
    background-color: #72BF5D;
    border-color: #72BF5D;
    width:370px;
}
button#refresh-order, button#complete-order, button#place-order, button#clear-cart {
    background-color: #9764AA;
    border-color: #9764AA;
   color:#000;
}
.keKQsv#refresh-order, .keKQsv#complete-order, .keKQsv#place-order, .keKQsv#clear-cart {
    float: right;
    min-width: 170px;
}

