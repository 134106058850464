table {
    display: block;
    overflow-y: scroll;

    &#cart-table {
        max-height: 320px;
        th {
            top: 0rem;
        }
    }

    &#product-table {
        max-height: 550px;
        width: 100%;
        th {
            top: 0rem;
            &:last-child {
                min-width: 120px;
            }
            &:nth-child(5) {  
                min-width: 100px;
            }
        }
    }
    
    th {
        width: 10%;
        &:last-child {
            width: 15%;
        }
        &:first-child {
            width: 5%;
        }
    }

    .error {
        color: #DB3643;
        font-size: 10px;
    }
}
#add-to-order {
    position: absolute;
    right: 0;
    top: -35px;
}
#product-form {
    position: relative;
}
